import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormListItem } from '../../models/Form';
import FormIcon from '../shared/icon/ActivityIcon';
import ShareIcon from '../shared/icon/ShareIcon';
import FormUtils from '../../utils/FormUtils';
import UserIcon from '../shared/icon/UserIcon';
import Tooltip from '../shared/Tooltip';
import { FormType } from '../../models/FormTypes';
import DocumentSpaceIcon from '../shared/icon/DocumentSpaceIcon';
import { useCurrentClient } from '../../global-state/Clients';

type AssetIconProps = {
  form: FormListItem;
};

const ActivityIcon: FC<AssetIconProps> = (props) => {
  const { form } = props;
  const { t } = useTranslation('common');
  const client = useCurrentClient((x) => x.value);

  const isHost = useMemo(() => form?.clientId === client?.id, [client?.id, form?.clientId]);

  const tooltip = useMemo(() => {
    if (form.isShared) {
      return isHost
        ? t('forms.share.tooltip.host', { count: form.shares.length })
        : t('forms.share.tooltip.receiver', { name: FormUtils.formHostName(form, client?.id || '') });
    }

    let type;
    switch (form.type) {
      case FormType.Asset:
        type = t('forms.icon.tooltip.activity');
        break;
      case FormType.Document:
        type = t('forms.icon.tooltip.document');
        break;
      default:
        type = t('forms.icon.tooltip.task');
    }

    return form.isPublic ? t('forms.icon.tooltip.public', { type }) : type;
  }, [client?.id, form, isHost, t]);

  const IconToUse = form.type === FormType.Document ? DocumentSpaceIcon : FormIcon;

  return (
    <Tooltip text={tooltip}>
      {(tooltip) => (
        <div {...tooltip} className="relative mr-4 h-8 w-8">
          <IconToUse className="bg-gray-1 h-8 w-8 flex-shrink-0 rounded-full p-1 text-white" />
          {form.isShared && form && (
            <>
              <div className="bg-primary-2 absolute -right-1 top-0 flex h-4 w-4 items-center rounded-full p-1">
                <ShareIcon className="text-s h-2 w-2" />
              </div>
            </>
          )}
          {form?.isPublic && (
            <div className="bg-progress-bar-1 absolute -right-1 top-0 flex h-4 w-4 items-center rounded-full p-1">
              <UserIcon className="h-2 w-2 text-white" />
            </div>
          )}
        </div>
      )}
    </Tooltip>
  );
};

export default ActivityIcon;
